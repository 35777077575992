import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 placeholder-gray-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Fill out the form and our team will get back to you at the earliest.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [captchaNum, setCaptchaNum] = useState({ one: "", two: "" });

  const getCaptcha = async () => {
    // Called on Mount to generate captcha
    try {
      const res = await fetch(
        "https://production.inhabyt.io/api/home/contact-captcha"
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const { one, two } = await res.json();

      setCaptchaNum({ one, two });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCaptcha();
  }, []);

  const handleForm = async (e) => {
    e.preventDefault();

    const form = e.target;
    const name = form.name?.value;
    const companyName = form.companyName?.value;
    const companyEmail = form.companyEmail?.value;
    const state = form.state?.value;
    const message = form.message?.value;
    const captcha = +form.captcha?.value;

    if (
      !name ||
      !companyName ||
      !companyEmail ||
      !state ||
      !message ||
      !captcha
    ) {
      alert("Form fields are not accessible or missing");
      return;
    }

    if (captcha !== captchaNum.one + captchaNum.two) {
      alert("Captcha is incorrect");
      return;
    }

    const formData = {
      name,
      companyName,
      companyEmail,
      state,
      message,
      captcha,
      captchaOne: captchaNum.one,
      captchaTwo: captchaNum.two,
    };

    // submit form data to api
    try {
      const response = await fetch(
        "https://production.inhabyt.io/api/home/contact-us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        alert("Message Sent Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleForm}>
              <Input type="text" name="name" placeholder="Name" />
              <Input
                type="text"
                name="companyName"
                placeholder="Company Name"
              />
              <Input
                type="email"
                name="companyEmail"
                placeholder="Company Email"
              />
              <Input type="text" name="state" placeholder="City" />
              <Textarea name="message" placeholder="Your Message Here" />
              <Input
                type="number"
                name="captcha"
                placeholder={`Solve:\u00A0 ${captchaNum.one} + ${captchaNum.two}`}
              />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
