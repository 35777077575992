import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import DeveloperImage from "images/developer.png";
import ConsumerImage from "images/consumer.png";
import ManufacturerImage from "images/manufacturer.png";
import RetailorImage from "images/retailer.png";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/4 max-w-xs mt-5 lg:mt-0`}
`;

const Card = styled.a`
  ${tw`flex border flex-col items-center h-full mx-3 px-3 py-8 rounded-3xl transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`rounded-full`}
    img {
      ${tw`w-24 h-24`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`text-sm font-medium text-secondary-200`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;
const FeatureIconContainer = styled.div`
  ${tw`text-primary-500`}

  svg {
    ${tw`w-4 h-4 mt-1`}
  }
`;
const FeatureContainer = styled.div`
  ${tw`flex mt-5 gap-2`}
`;

export default ({
  cards = [
    {
      imageSrc: ConsumerImage,
      title: "Consumers",
      description1: "Effortless product discovery from anywhere at anytime.",
      description2: "Instant 3D visualization and virtual room customization.",
      description3: "Compare and share looks to collaborate with others.",
      description4:
        "Realtime price estimation helps you stay within your budget.",
      description5: "Hassle free process for quick and confident decisions.",
    },
    {
      imageSrc: ManufacturerImage,
      title: "Manufacturers",
      description1:
        "An interactive catalogue that comes with mockups for every scenario.",
      description2:
        "Large format interactive displays to enhance your experience centers.",
      description3:
        "Take the showroom to institutional clients and boost B2B sales.",
      description4:
        "Broader and deeper market penetration across all territories.",
      description5:
        "Access to consented user info to help follow-up and close warm leads.",
    },
    {
      imageSrc: RetailorImage,
      title: "Retailers",
      description1:
        "Showcase interactive mockups instead of investing in physical ones.",
      description2:
        "Display a larger selection of products that isn't limited by store space.",
      description3:
        "A sales tool to convert more customers and close sales much faster.",
      description4:
        "Avoid customer bottlenecks when catalogues and staff are occupied.",
      description5:
        "Drastically reduces overheads spent on updating physical mockups/displays.",
    },
    {
      imageSrc: DeveloperImage,
      title: "Developers",
      description1: "Showcase interior customization options to homebuyers.",
      description2:
        "Frees resources since customers can browse at their own convenience.",
      description3:
        "Eliminates the need for a designer to be involved throughout the process.",
      description4:
        "A realtime BOM with visuals ensures nothing is lost in translation.",
      description5: "Reduces the chances of errors when executing the project.",
    },
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        <Subheading>BENEFITS</Subheading>
        <Heading>
          Something for <span tw="text-primary-500">everyone.</span>
        </Heading>
        <Description>
          Our services benefit stakeholders across the value chain.
        </Description>
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <FeatureContainer>
                  <FeatureIconContainer>
                    {<CheckboxIcon />}
                  </FeatureIconContainer>
                  <p className="description">{card.description1}</p>
                </FeatureContainer>
                <FeatureContainer>
                  <FeatureIconContainer>
                    {<CheckboxIcon />}
                  </FeatureIconContainer>
                  <p className="description">{card.description2}</p>
                </FeatureContainer>
                <FeatureContainer>
                  <FeatureIconContainer>
                    {<CheckboxIcon />}
                  </FeatureIconContainer>
                  <p className="description">{card.description3}</p>
                </FeatureContainer>
                <FeatureContainer>
                  <FeatureIconContainer>
                    {<CheckboxIcon />}
                  </FeatureIconContainer>
                  <p className="description">{card.description4}</p>
                </FeatureContainer>
                <FeatureContainer>
                  <FeatureIconContainer>
                    {<CheckboxIcon />}
                  </FeatureIconContainer>
                  <p className="description">{card.description5}</p>
                </FeatureContainer>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
